import { useState, useEffect } from 'react'
const windowGlobal = typeof window !== 'undefined' && window

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = windowGlobal
	return {
		width,
		height,
	}
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions())
		}

		windowGlobal.addEventListener('resize', handleResize)
		return () => windowGlobal.removeEventListener('resize', handleResize)
	}, [])

	return windowDimensions
}
