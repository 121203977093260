import React from 'react'
import PropTypes from 'prop-types'
const Complex = ({ size, color, ...otherProps }) => (
	<svg
		id="Complex Pool"
		aria-hidden="true"
		focusable="false"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		aria-label="Complex Pool"
		width={size ? size : ''}
		viewBox="0 0 66.81 107.08"
		{...otherProps}
	>
		<title>Round Pool</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Wizard">
				<path
					className="cls-1"
					fill="#394253"
					d="M0,0H66.8c.06.34-.17.56-.3.8a129.09,129.09,0,0,0-10.7,26C55,29.69,54,32.57,53.49,35.56c-1,5.22-1,10.37,1.15,15.36a50.44,50.44,0,0,0,4.62,7.91A37.81,37.81,0,0,1,65,70.73a34,34,0,0,1-.1,17.67c-2.1,7.37-7,12.31-13.91,15.35A41.13,41.13,0,0,1,38,106.86a51.31,51.31,0,0,1-14.32-.69,32.73,32.73,0,0,1-13.07-5.3,22.59,22.59,0,0,1-9.39-15A34.67,34.67,0,0,1,4.09,64.21,95.85,95.85,0,0,1,9,55.86a28.16,28.16,0,0,0,4-9.58,31.24,31.24,0,0,0,0-10.21C12.15,29.68,9.9,23.7,7.5,17.76S2.72,6.23.22.51ZM33.31,2.64H4.79c-.76,0-.76,0-.46.71,1.9,4.41,3.77,8.84,5.57,13.3,2.36,5.84,4.59,11.72,5.6,18A34.74,34.74,0,0,1,15.58,47a32.45,32.45,0,0,1-4.79,11,77.94,77.94,0,0,0-4.36,7.37A32.3,32.3,0,0,0,3.84,85.34,20,20,0,0,0,9.51,96.46a25.92,25.92,0,0,0,11.83,6.45,46.58,46.58,0,0,0,15.86,1.38,38.48,38.48,0,0,0,13.06-3.12c5.65-2.56,9.77-6.59,11.79-12.55A29.73,29.73,0,0,0,63.32,77.1a29.44,29.44,0,0,0-3.64-12.52C58.24,62,56.54,59.49,54.9,57a27.38,27.38,0,0,1-4.36-19.67,82.15,82.15,0,0,1,2.31-9.9,134.29,134.29,0,0,1,9.32-24c.37-.75.37-.75-.48-.75Z"
				/>
				<path
					className="cls-2"
					fill={color}
					d="M33.31,2.64H61.69c.85,0,.85,0,.48.75a134.29,134.29,0,0,0-9.32,24,82.15,82.15,0,0,0-2.31,9.9A27.38,27.38,0,0,0,54.9,57c1.64,2.5,3.34,5,4.78,7.59A29.44,29.44,0,0,1,63.32,77.1a29.73,29.73,0,0,1-1.27,11.52c-2,6-6.14,10-11.79,12.55a38.48,38.48,0,0,1-13.06,3.12,46.58,46.58,0,0,1-15.86-1.38A25.92,25.92,0,0,1,9.51,96.46,20,20,0,0,1,3.84,85.34,32.3,32.3,0,0,1,6.43,65.41,77.94,77.94,0,0,1,10.79,58a32.45,32.45,0,0,0,4.79-11,34.74,34.74,0,0,0-.08-12.41c-1-6.26-3.24-12.14-5.6-18C8.1,12.19,6.23,7.76,4.33,3.35c-.3-.71-.3-.71.46-.71Zm0,60A14.38,14.38,0,1,0,47.74,77,14.39,14.39,0,0,0,33.36,62.66Z"
				/>
				<path
					className="cls-1"
					fill="#394253"
					d="M33.36,62.66A14.38,14.38,0,1,1,19,76.9,14.35,14.35,0,0,1,33.36,62.66ZM21.61,77A11.76,11.76,0,1,0,33.19,65.28,11.77,11.77,0,0,0,21.61,77Z"
				/>
				<path
					className="cls-3"
					fill="#fefefe"
					d="M21.61,77A11.76,11.76,0,1,1,33.37,88.78,11.79,11.79,0,0,1,21.61,77Z"
				/>
			</g>
		</g>
	</svg>
)
Complex.propTypes = {
	size: PropTypes.string,
	color: PropTypes.string,
}
Complex.defaultProps = {
	size: '',
	color: '#c3c3c3',
}
export default Complex
