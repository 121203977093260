import React from 'react'
import PropTypes from 'prop-types'
const RoundPool = ({ size, color, ...otherProps }) => (
	<svg
		id="Round Pool"
		aria-hidden="true"
		focusable="false"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		aria-label="Round Pool"
		width={size ? size : ''}
		viewBox="0 0 65.36 107.1"
		{...otherProps}
	>
		<title>Round Pool</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Wizard">
				<path
					fill="#394253"
					className="cls-1"
					d="M32.79,0A32.36,32.36,0,0,1,45.68,2.32C51.8,5,55.13,9.78,55.88,16.38a31.9,31.9,0,0,1-1.54,13.15,44.18,44.18,0,0,0-2.14,11.9,23.75,23.75,0,0,0,3.7,13.24c1.71,2.8,3.64,5.46,5.25,8.33a32.54,32.54,0,0,1,4.2,15.44,30.8,30.8,0,0,1-1.83,12,23.36,23.36,0,0,1-12.75,13.08,40.4,40.4,0,0,1-13.41,3.32,51,51,0,0,1-13.52-.55,33.38,33.38,0,0,1-13.53-5.21A22.57,22.57,0,0,1,.59,85.84,35.1,35.1,0,0,1,5.79,60c1.51-2.34,3-4.68,4.31-7.17a24.87,24.87,0,0,0,2.66-13.1A56.82,56.82,0,0,0,10.39,27.3,28,28,0,0,1,9.33,16.23c.83-6.92,4.56-11.61,11-14.19A33.85,33.85,0,0,1,32.79,0ZM32.5,2.67A30,30,0,0,0,21.73,4.33c-4.88,1.82-8.22,5.07-9.45,10.25a21.77,21.77,0,0,0,.28,10.49c1.16,4.59,2.49,9.14,2.8,13.91a28.88,28.88,0,0,1-1.43,11.63A46.25,46.25,0,0,1,9,59.87a43.67,43.67,0,0,0-4.5,8.59A33.3,33.3,0,0,0,3.05,84.69a20.71,20.71,0,0,0,3.81,9.48c3.15,4.27,7.49,6.8,12.46,8.36a45.66,45.66,0,0,0,16.88,1.8,38.79,38.79,0,0,0,13.33-3.11c5.91-2.66,10.1-6.89,12-13.17a30.31,30.31,0,0,0,1.08-11A29.38,29.38,0,0,0,59.1,64.77c-1.63-3-3.63-5.74-5.41-8.64A26.42,26.42,0,0,1,49.56,41a47.65,47.65,0,0,1,2.28-12.36,28.12,28.12,0,0,0,1.32-12.77A13.78,13.78,0,0,0,44.31,4.61,29.73,29.73,0,0,0,32.5,2.67Z"
				/>
				<path
					fill={color}
					className="cls-2"
					d="M32.5,2.67A29.73,29.73,0,0,1,44.31,4.61a13.78,13.78,0,0,1,8.85,11.27,28.12,28.12,0,0,1-1.32,12.77A47.65,47.65,0,0,0,49.56,41a26.42,26.42,0,0,0,4.13,15.12c1.78,2.9,3.78,5.65,5.41,8.64A29.38,29.38,0,0,1,62.65,77a30.31,30.31,0,0,1-1.08,11c-1.94,6.28-6.13,10.51-12,13.17a38.79,38.79,0,0,1-13.33,3.11,45.66,45.66,0,0,1-16.88-1.8c-5-1.56-9.31-4.09-12.46-8.36a20.71,20.71,0,0,1-3.81-9.48A33.3,33.3,0,0,1,4.49,68.46,43.67,43.67,0,0,1,9,59.87a46.25,46.25,0,0,0,4.94-9.26A28.88,28.88,0,0,0,15.36,39c-.31-4.77-1.64-9.32-2.8-13.91a21.77,21.77,0,0,1-.28-10.49c1.23-5.18,4.57-8.43,9.45-10.25A30,30,0,0,1,32.5,2.67Z"
				/>
			</g>
		</g>
	</svg>
)
RoundPool.propTypes = {
	size: PropTypes.string,
	color: PropTypes.string,
}
RoundPool.defaultProps = {
	size: '',
	color: '#57c7dd',
}
export default RoundPool
